<template>
  <login-wrapper class="register">
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      label-width="80px"
    ></dog-form>
    <div class="register__operate">
      <el-link :underline="false" type="primary" @click="goLogin">
        返回登录
      </el-link>
    </div>
    <el-button type="primary" class="register__btn" @click="register">
      注册
    </el-button>
  </login-wrapper>
</template>

<script>
  import userService from '@/api/service/user';
  import loginWrapper from './loginWrapper.vue';
  import emailCode from '@/components/emailCode.vue';
  import md5 from 'js-md5';

  export default {
    name: 'register',
    components: { loginWrapper },
    data() {
      return {
        formData: {}
      };
    },
    computed: {
      formOptions() {
        return [
          {
            type: 'input',
            formItem: {
              label: '用户名',
              prop: 'userName',
              rules: [this.$formRules.required('请输入用户名')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '邮箱',
              prop: 'email',
              rules: [this.$formRules.email()]
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '验证码',
              prop: 'code',
              rules: []
            },
            component: emailCode,
            attrs: {
              email: this.formData.email
            }
          },
          {
            type: 'input',
            formItem: {
              label: '密码',
              prop: 'password',
              rules: [this.$formRules.required('请输入密码')]
            },
            attrs: {
              type: 'password',
              showPassword: true
            }
          },
          {
            type: 'input',
            formItem: {
              label: '确认密码',
              prop: 'password2',
              rules: [this.$formRules.required('请再次输入密码')]
            },
            attrs: {
              type: 'password',
              showPassword: true
            }
          }
        ];
      }
    },
    methods: {
      /**
       * 注册
       */
      register() {
        if (this.formData.password !== this.formData.password2) {
          this.$message.error('两次新密码不一致！');
          return;
        }
        this.$refs.dogForm.validate().then(() => {
          userService
            .register({
              userName: this.formData.userName,
              email: this.formData.email,
              code: this.formData.code,
              password: md5(this.formData.password)
            })
            .then(() => {
              this.goLogin();
            });
        });
      },
      /**
       * 返回登录
       */
      goLogin() {
        this.$router.push({
          path: '/login',
          query: this.$route.query
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(register) {
    @include e(operate) {
      margin-bottom: 25px;
      text-align: right;
    }
    @include e(btn) {
      width: 100%;
    }
  }
  /deep/ .checkCode {
    align-items: center;
  }
</style>
